<template>
  <div>
    <div :class="['comment-list', scrollable? 'scroll': '']">
      <div class="detail-label mb-2">
        <span>{{$t('comment.title')}} ({{comments.length}})</span>
        <button v-if='canAdd() && showAdd' class="btn-action" :id="`BTN_ADD_${id}`" @click="commentAdd"><font-awesome-icon :icon="['far', 'plus']"/></button>
        <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top">
          {{ $t('comment.button.add_comment') }}
        </b-popover>
      </div>
      <ul ref="comments" class="mt-1 comment-container" v-show="comments.length > 0">
        <template v-for="(comment, index) in comments">
          <Comment :isDialog="isDialog" :comment="comment" :comments="comments" :authorMap="authorMap" :requestState="requestState" @reload='loadData' @edit="edit" @error='commentError' :key="index"/>
        </template>
      </ul>
    </div>

    <CommentModal :holderid="id" :comments="comments" :show.sync="modalCommentShow" @success="commentSuccess" @reload="loadData"/>
    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { strRandom } from '@/helpers'; 
import { userService } from '@/services';

export default {
  name: 'CommentList',
  components: { 
    CommentModal: () => import('@/components/modal/CommentModal.vue'),
    Comment:      () => import('@/components/Comment/Comment.vue')
  },
  props: {
    id:            { type: String,   default: `COMMENT_LIST_${strRandom(5)}` },
    comments:      { type: Array, required: true },
    showAdd:       { type: Boolean, default: true },
    isDialog:      { type: Boolean, default: false},
    scrollable:    { type: Boolean, default: true }
  },
  data() {
    return {
      permissionName: 'NOTE',
      modalCommentShow: false,
      authorMap: [],
      requestState: ''
    }
  },
  created() {
    this.mapAuthors(this.comments);
  },
  watch: {
    comments: function(val) {
      this.mapAuthors(val);
    }
  },
  methods: {
    mapAuthors(val) {
      for (let i = 0, len = val.length; i < len; i++) {
        const authorRef = val[i].authorRef;
        if (typeof authorRef !== 'undefined' &&
            this.requestState.indexOf(authorRef) === -1) {
          this.requestState += authorRef;
          userService.get([{ uuId: authorRef }]).then(response => {
            this.authorMap.push(cloneDeep(response.data[response.data.jobCase][0]));
          }, () => {
            this.$emit('error', this.$t('comment.error.failed_to_get_author_detail'));
          });
        }
      }
    },
    commentSuccess(payload) {
      this.$emit('success', payload);
    },
    commentError(payload) {
      this.$emit('error', payload);
    },
    commentAdd() {
      if (!this.isDialog) {
        this.modalCommentShow = true;
      }
      else {
        this.$emit('add');
      }
    },
    edit(comment) {
      this.$emit('edit', comment);
    },
    loadData() {
      this.$emit('reload');
    }
  }
}
</script>

<style lang="scss">
.comment-list ul {
  list-style-type: none;
  margin: 10px 0 10px 0;
  padding: 0;
  min-height: 48px;
}

.comment-list.scroll ul {
  overflow-y: scroll;
  max-height: 200px;  
}

.comment-list {
  .add-icon {
    border: none;
    margin-left: 4px;
    padding-left: 6px;
    padding-right: 6px;

    &, &[disabled]:active {
      background-color: transparent;
      border: 0;
    }
    
    &:focus,&:active:focus,
    &.btn-secondary:active:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.comment-container {
  border: 1px solid var(--form-control-border);
  border-radius: 5px;
}

</style>