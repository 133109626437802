var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { class: ["comment-list", _vm.scrollable ? "scroll" : ""] }, [
        _c(
          "div",
          { staticClass: "detail-label mb-2" },
          [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("comment.title")) +
                  " (" +
                  _vm._s(_vm.comments.length) +
                  ")"
              ),
            ]),
            _vm.canAdd() && _vm.showAdd
              ? _c(
                  "button",
                  {
                    staticClass: "btn-action",
                    attrs: { id: "BTN_ADD_" + _vm.id },
                    on: { click: _vm.commentAdd },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "plus"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "BTN_ADD_" + _vm.id,
                  triggers: "hover",
                  placement: "top",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("comment.button.add_comment")) + " ")]
            ),
          ],
          1
        ),
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.comments.length > 0,
                expression: "comments.length > 0",
              },
            ],
            ref: "comments",
            staticClass: "mt-1 comment-container",
          },
          [
            _vm._l(_vm.comments, function (comment, index) {
              return [
                _c("Comment", {
                  key: index,
                  attrs: {
                    isDialog: _vm.isDialog,
                    comment: comment,
                    comments: _vm.comments,
                    authorMap: _vm.authorMap,
                    requestState: _vm.requestState,
                  },
                  on: {
                    reload: _vm.loadData,
                    edit: _vm.edit,
                    error: _vm.commentError,
                  },
                }),
              ]
            }),
          ],
          2
        ),
      ]),
      _c("CommentModal", {
        attrs: {
          holderid: _vm.id,
          comments: _vm.comments,
          show: _vm.modalCommentShow,
        },
        on: {
          "update:show": function ($event) {
            _vm.modalCommentShow = $event
          },
          success: _vm.commentSuccess,
          reload: _vm.loadData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }